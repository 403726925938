// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

 

/* eslint-disable max-len */

export const AdminAppEnvironment = {
    production: true,
    version: "1.1.10+26",
    material_number: "000000-1012-172",
    location: 'global',
    authentication: {
        autoLogoutInMinutes: 30,
        signinProfile: "B2C_1A_ZeissIdNormalSignIn",
        signupProfile: "B2C_1A_ZeissIdNormalSignUp",
        clientId: "8cb5b97f-8144-479d-99bd-dda55397ce02",
        disableAtHashCheck: true,
        clearHashAfterLogin: true,
        redirectUri: "https://vcld-admin-portal-prod.azurewebsites.net",
        requestAccessToken: false,
        responseType: "code",
        scope: "openid profile offline_access",
        showDebugInformation: false,
        skipIssuerCheck: true,
        strictDiscoveryDocumentValidation: false,
        timeoutFactor: 0.75,
        overrideDiscoveryDocumentUri:
            "https://id-ip.zeiss.com/CD/OAuth/OpenidConfiguration?p=B2C_1A_ZeissIdNormalSignIn",
        overrideDiscoveryDocumentUriSignUp:
            "https://id-ip.zeiss.com/CD/OAuth/OpenidConfiguration?p=B2C_1A_ZeissIdNormalSignUp",
    },
    monitoring: {
        appInsightsInstrumentationKey: "8d4acdb1-27bc-49ae-a763-9c7b380fcb80",
    },
    connectivity: {
        frontdoorApiBaseUrl: null,
        appConfig: "https://vcldcommonstaticfiles.blob.core.windows.net/configuration/prod/admin-config.json",
        translations: "https://vcldcommonstaticfiles.blob.core.windows.net/translations/prod/admin/",
        translation_registry: "https://vcldcommonstaticfiles.blob.core.windows.net/translations/prod/registry/admin.json",
        esbBaseUrl: "https://esb.zeiss.com/public/api/vis",
        fpsEndpoint: "https://esb.zeiss.com/public/api/vis/vcld/FPS",
        zeiss_logo:
            "https://zeissid-cdn.azureedge.net/src/images/zeiss-logo.svg",
        ecp_settings:
            "https://esb.zeiss.com/public/api/vis/vcld-admin/ecp-settings",
        demo_avatar: 
            "https://esb.zeiss.com/public/api/vis/vcld-admin/demo-avatar",
        avatarSessionEndpoint:
            "https://esb.zeiss.com/public/api/vis/vcld-admin/AvatarCreationSessions/{opticianId}",
        avatarsCountEndpoint:
            "https://esb.zeiss.com/public/api/vis/vcld-admin/AvatarSessions/{opticianId}",
        resendInvitationEndpoint: 
            "https://esb.zeiss.com/public/api/vis/vcld-admin/resendinvitation",
        email_endpoint:
            "https://esb.zeiss.com/public/api/vis/vcld/contact-mail",
        deleteAvatarSessionEndpoint:
            "https://esb.zeiss.com/public/api/vis/vcld-admin/AvatarCreationSessions",
        securityEndpoint:
            "https://esb.zeiss.com/public/api/vis/vcld-admin/security",
        centration:
            "https://esb.zeiss.com/public/api/vis/vcld-admin/centration/centration-jobs/",
        ecp_onboarding:
            "https://esb.zeiss.com/public/api/vis/vcld-admin/ecp-onboarding",
        coBrandingLogo:
            "https://esb.zeiss.com/public/api/vis/vcld-admin/ecp-branding",
        getDefaultBanner:
            "https://esb.zeiss.com/public/api/vis/vcld-admin/default-img",
        customBanner:
            "https://esb.zeiss.com/public/api/vis/vcld-admin/custom-img/{opticianId}",
        invitationTemplates:
            "https://esb.zeiss.com/public/api/vis/vcld-admin/invitation-template/{opticianId}",
        invitationComponents: 
            "https://esb.zeiss.com/public/api/vis/vcld-admin/invitation-component",
        previewInvitationTemplate:
            "https://esb.zeiss.com/public/api/vis/vcld-admin/preview-invitation-template/{opticianId}",
        gfdbCDNConnectionEndpoint:"https://gfdb-prod.azureedge.net/prodcdnvisualizationassetstore",
        getStores: "https://esb.zeiss.com/public/api/vis/vcld-admin/opticians",
        marketing: {
            getStores: "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/opticians",
            createCampaign:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/campaign/{opticianId}",
            getCustomers:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/customers/{opticianId}",
            selectCustomers:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/select-customers",
            getFrames:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/framecollections/{opticianId}",
            selectFrames:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/filter",
            getEmailTemplates:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/templates/{opticianId}",
            getEmailComponents:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/component",
            createOrUpdateTemplate:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/templates/{opticianId}",
            previewTemplate:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/previewtemplate/{opticianId}",
            getAvatarThumbnail:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/avatars/{sessionId}/thumbnail",
            getFrameThumbnail:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/frames/{frameId}/thumbnail",
            submitCampaign:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/sendcampaign",
            setRecommendationSettings:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/campaign",
            setSelectedRecommendation:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/recommendation",
            getDMTLogo:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/{opticianId}/ecplogo",
            settings:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/settings/{opticianId}",
            recommendationProgress:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/sessionStatus/{campaignId}",
            customDMTBanner:
                "https://esb.zeiss.com/public/api/vis/vcld-admin/marketing/banner-img/{templateId}",
            getFrameThumbnailCDN:
                "https://gfdb-prod.azureedge.net/prodcdnvisualizationassetstore/{frameId}/INSTORE/THUMBNAIL.PNG"
        },
    },
    contact: {
        zeiss_support: "jochen.uebelhoer@zeiss.com",
        form_stack: "https://myzeiss.formstack.com/forms/servicerequest_admin",
    },
};

 

export const ConsumerAppEnvironment = {
    production: true,
    version: "1.1.10+26",
    material_number: "000000-1012-171",
    location: 'global',
    authentication: {
        autoLogoutInMinutes: 30,
        signinProfile: "B2C_1A_ZeissIdEMailOnlySignIn",
        signupProfile: "B2C_1A_ZeissIdEMailOnlySignUp",
        clientId: "d5a465c6-27ed-4729-80c9-c9bb530cbba4",
        disableAtHashCheck: true,
        clearHashAfterLogin: true,
        redirectUri: "https://vcld-consumer-portal-prod.azurewebsites.net",
        requestAccessToken: false,
        responseType: "code",
        scope: "openid profile offline_access",
        showDebugInformation: false,
        skipIssuerCheck: true,
        strictDiscoveryDocumentValidation: false,
        timeoutFactor: 0.75,
        overrideDiscoveryDocumentUri:
            "https://id-ip.zeiss.com/CD/OAuth/OpenidConfiguration?p=B2C_1A_ZeissIdEMailOnlySignIn",
        overrideDiscoveryDocumentUriSignUp:
            "https://id-ip.zeiss.com/CD/OAuth/OpenidConfiguration?p=B2C_1A_ZeissIdEMailOnlySignUp",
    },

 

    monitoring: {
        appInsightsInstrumentationKey: "8d4acdb1-27bc-49ae-a763-9c7b380fcb80",
    },

 

    connectivity: {
        frontdoorApiBaseUrl: null,
        introImages: "https://vcldcommonstaticfiles.blob.core.windows.net/assets/prod/intro",
        appConfig: "https://vcldcommonstaticfiles.blob.core.windows.net/configuration/prod/consumer-config.json",
        appLinksConfig: "https://vcldcommonstaticfiles.blob.core.windows.net/translations/prod/links/rights-links.json",
        translations: "https://vcldcommonstaticfiles.blob.core.windows.net/translations/prod/consumer/",
        translation_registry: "https://vcldcommonstaticfiles.blob.core.windows.net/translations/prod/registry/consumer.json",
        frameVariants: "https://esb.zeiss.com/public/api/vis/vcld/frame",
        esbBaseUrl: "https://esb.zeiss.com/public/api/vis/vcld",
        dmt_link_used:
            "https://esb.zeiss.com/public/api/vis/vcld/mailaccess",
        email_endpoint:
            "https://esb.zeiss.com/public/api/vis/vcld/contact-mail",
        frame_selection_endpoint:
            "https://esb.zeiss.com/public/api/vis/vcld/select-frame",
        avatarSessionEndpoint:
            "https://esb.zeiss.com/public/api/vis/vcld/sessions",
        deleteAvatarSessionEndpoint:
            "https://esb.zeiss.com/public/api/vis/vcld/{id}/avatar",
        avatarSessionBinary:
            "https://esb.zeiss.com/public/api/vis/vcld/{id}/binary",
        framesEndpoint: "https://esb.zeiss.com/public/api/vis/vcld/frames",
        favoriteEndpoint:
            "https://esb.zeiss.com/public/api/vis/vcld/favorites",
        generatePublicDomain:
            "https://esb.zeiss.com/public/api/vis/vcld/share/{sessionId}",
        getPublicFavorites:
            "https://esb.zeiss.com/public/api/vis/vcld/share/favorites/{sessionId}?token={token}",
        getRatings:
            "https://esb.zeiss.com/public/api/vis/vcld/ratings/{sessionId}",
        getSurveys:
            "https://esb.zeiss.com/public/api/vis/vcld/surveys/{frameId}?sessionId={sessionId}",
        postSurveys:
            "https://esb.zeiss.com/public/api/vis/vcld/surveys?token={token}",
        fetchImageForSocialShare:
            "https://esb.zeiss.com/public/api/vis/vcld/share/image/{frameId}.jpg?token={token}&sessionId={sessionId}",
        socialMetaTagEndpoint:
            "https://vcld-api-prod.azurewebsites.net/api/open-graph-tags?token={accessToken}&sessionId={sessionId}&frameId={frameId}",
        fpsEndpoint: "https://esb.zeiss.com/public/api/vis/vcld/FPS",
        recommendationsEndpoint:
            "https://esb.zeiss.com/public/api/vis/vcld/recommendations",

 

        lastAccessEndpoint:
            "https://esb.zeiss.com/public/api/vis/vcld/lastAccess",
        coBrandingLogo:
            "https://esb.zeiss.com/public/api/vis/vcld/branding/logo",
        ecpSettings:
            "https://esb.zeiss.com/public/api/vis/vcld/branding/features/{opticianId}",
        removeDMTRecommendation:
            "https://esb.zeiss.com/public/api/vis/vcld/recommendations",
        unsubscribeEndpoint:
            "https://esb.zeiss.com/public/api/vis/vcld/marketing/unsubscribe",
        statisticsEndpoint:
            "https://esb.zeiss.com/public/api/vis/vcld/statistics/interaction",
        tintsAndCoatingEndpoint: "https://esb.zeiss.com/public/api/vis/vcld/consumer/tints-coating",
        updateVtoSessionEndpoint: "https://esb.zeiss.com/public/api/vis/vcld/session/{sessionId}/frames",
        gfdbCDNConnectionEndpoint: "https://gfdb-prod.azureedge.net/prodcdnvisualizationassetstore",
        appIds: {
            facebook: {
                prod: "2547357745318966",
                dev: "438678780138770",
            },
        },

 

        socialMedia: {
            facebook: "https://www.facebook.com/sharer/sharer.php?u={url}",
        },
    },

 

    contact: {
        zeiss_support: "jochen.uebelhoer@zeiss.com",
        form_stack:
            "https://myzeiss.formstack.com/forms/servicerequest_consumer_webapp",
    },

 

    content: {
        logo: "https://zeissid-cdn.azureedge.net/src/images/zeiss-logo.svg",
    },
};

 

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
